<h2 mat-dialog-title>
  Automatik
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <p>Automatik gør det muligt for kunden at automatisere forskellige handlinger, fx at tilknytte eller fjerne tags baseret på bestemte handlinger.</p>
  <form [formGroup]="form">
    <div>
      <mat-form-field class="automation-settings-select">
        <mat-label>Triggers</mat-label>
        <mat-select formControlName="triggers" multiple placeholder="Vælg triggers">
          <mat-optgroup label="Ansøgning">
            <mat-option value="submitted">modtages</mat-option>
            <mat-option value="project_resubmitted">genindsendes</mat-option>
            <mat-option value="resubmitable">sendes retur</mat-option>
            <mat-option value="refused">modtager afslag</mat-option>
            <mat-option value="approved">bevilges</mat-option>
            <mat-option value="switches_category">skifter kategori</mat-option>
            <mat-option value="associates_meeting">tilknyttes møde</mat-option>
            <mat-option value="associates_case_worker">tilknyttes sagsbehandler</mat-option>
            <mat-option value="associates_expert">tilknyttes ekspert</mat-option>
            <mat-option value="notified">bevillingsbrev sendes</mat-option>
            <mat-option value="project_archived">arkiveret</mat-option>
            <mat-option value="project_tag_added">tag tilføjes</mat-option>
            <mat-option value="project_user_incompetent">bruger meldes inhabil</mat-option>
            <mat-option value="project_user_competent">bruger afmeldes inhabil</mat-option>
            <mat-option value="payment_plan_locked">bevillingsopdeling bekræftes"</mat-option>
          </mat-optgroup>
          <mat-optgroup label="Sagsbehandling">
            <mat-option value="case_document_published">udgives</mat-option>
            <mat-option value="case_document_drafted">skifter til kladde</mat-option>
          </mat-optgroup>
          <mat-optgroup label="Indstilling">
            <mat-option value="recommendation_published">udgives</mat-option>
          </mat-optgroup>
          <mat-optgroup label="Besked">
            <mat-option value="message_to_applicant">sendes til ansøger</mat-option>
            <mat-option value="message_from_applicant">modtages fra ansøger</mat-option>
            <mat-option value="message_read">læst</mat-option>
          </mat-optgroup>
          <mat-optgroup label="Betalinger">
            <mat-option value="payment_requested">udbetaling anmodet</mat-option>
            <mat-option value="payment_outgoing_confirmed">udbetaling ekspederet</mat-option>
            <mat-option value="payment_receipts_rejected">betalingsbilag afvist</mat-option>
            <mat-option value="payment_receipts_confirmed">betalingsbilag godkendt</mat-option>
            <mat-option value="project_report_payment_refused">betalingsrapport afvist</mat-option>
            <mat-option value="project_report_payment_approved">betalingsrapport godkendt</mat-option>
          </mat-optgroup>
          <mat-optgroup label="Afrapportering">
            <mat-option value="project_report_submitted">indsendt</mat-option>
            <mat-option value="project_report_approved">godkendt</mat-option>
            <mat-option value="project_report_deadline_exceeded">deadline overskredet</mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error>Vælg <strong>mindst</strong> 1 trigger.</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="automation-settings-select">
        <mat-label>Handlinger</mat-label>
        <mat-select formControlName="actions" multiple placeholder="Vælg handlinger">
          <mat-option value="tag_add">Tilføj tag</mat-option>
          <mat-option value="tag_remove">Fjern tag</mat-option>
          <mat-option value="case_worker_add">Tilføj sagsehandler</mat-option>
          <mat-option value="case_worker_remove">Fjern sagsbehandler</mat-option>
          <mat-option value="weighted_score_to_custom_value">Kopier vægtetscore til primærværdi</mat-option>
          <mat-option value="project_rating_pdf">Gem vurdering som PDF</mat-option>
          <mat-option value="archive">Arkiver sag</mat-option>
          <mat-option value="drop_grant">Nedskriv bevilling til 0</mat-option>
          <mat-option value="clear_marked">Fjern markeringer</mat-option>
          <mat-option value="assign_meeting">Tilføj til møde</mat-option>
          <mat-option value="expert_add">Tilføj ekspert</mat-option>
          <mat-option value="expert_remove">Fjern ekspert</mat-option>
          <mat-option value="create_or_link_missing_profile">Opret eller tilknyt manglende profil</mat-option>
        </mat-select>
        <mat-error>Vælg <strong>mindst</strong> 1 handling.</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
</mat-dialog-actions>
