<div fxLayout="column">
  <mat-card>
    <mat-card-title>Moduler</mat-card-title>
    <mat-card-content>
      <div fxLayoutAlign="space-between center">
        <div fxLayout="column">
          <mat-form-field>
            <mat-label>Søg efter modul</mat-label>
            <input type="text" name="search"
                   [formControl]="searchString"
                   matInput>
          </mat-form-field>
          <mat-checkbox ngDefaultControl [(ngModel)]="showSelected">
            Vis kun tilføjede moduler
          </mat-checkbox>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="component-list mt-3" *ngIf="filteredComponents$ | async as filteredComponents" [@listAnimation]="filteredComponents.length">
    <div fxLayout="row"
         [fxHide]="showSelected && !isSelected(component.slug)"
         *ngFor="let component of filteredComponents">
      <mat-card
        fxLayoutAlign="space-between center" fxFlex class="mb-2 mr-2 component-option">
        <span>{{component.name}}</span>
        <div fxLayoutGap="2rem">
          <button mat-icon-button
                  *ngIf="componentHasSettings(component.slug)"
                  (click)="editComponentSettings(component.slug)">
            <mat-icon>edit</mat-icon>
          </button>
          <mat-slide-toggle [checked]="selectedComponents.includes(component.id)"
                            (change)="selectComponent(component.id, $event.checked)"
                            ></mat-slide-toggle>
        </div>
      </mat-card>
    </div>
  </div>
</div>
