import {Component, OnInit} from '@angular/core';

import {CustomersService} from '../../core/services';
import {Customer} from '../../core/models';
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {fadeIn} from "../../core/animations/fadeInAnimation";

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss'],
  animations: [
    fadeIn
  ]
})
export class CustomersListComponent implements OnInit {

  customers: Customer[] = [];
  loading: boolean = true;

  searchString: FormControl = new FormControl('');
  filteredCustomerList$: Observable<Customer[]>;

  constructor(private customersService: CustomersService) {
  }

  ngOnInit(): void {
    this.getCustomers();
    this.customersService.onCustomerChange().subscribe(() => this.getCustomers());
  }

  getCustomers(): void {
    this.customersService.getCustomers()
      .subscribe(customers => {
        this.customers = customers;
        this.loading = false;
        this.initFilteredList();
      });
  }

  initFilteredList(): void {
    this.filteredCustomerList$ = this.searchString.valueChanges.pipe(startWith(' '))
      .pipe(map(search => this.customers.filter(u => {
        return u.name.toLocaleLowerCase().includes(search.toLowerCase()) || u.subdomain.toLowerCase().includes(search.toLowerCase())
        || u?.tag?.toLowerCase()?.includes(search.toLowerCase())
      })));
  }
}
