import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import localeDa from '@angular/common/locales/da';

import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule} from "@angular/material/checkbox";
import {MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, MatSlideToggleModule} from "@angular/material/slide-toggle";

import { FlexLayoutModule } from "@angular/flex-layout";

import { CookieService } from "ngx-cookie-service";

import { ErrorInterceptor, TokenInterceptor } from "./core/interceptors";
import { RandomBackgroundDirective } from './core/directives';
import { CustomersModule } from './customers/customers.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {MatDialogModule} from "@angular/material/dialog";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {ThemeSelectorComponent} from "./theme-toggle.component";
import {MaterialModule} from "./material.module";
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioDefaultOptions} from "@angular/material/radio";
import {MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS} from "@angular/material/button-toggle";

const options: any = {
  color: 'primary',
};
registerLocaleData(localeDa, 'da');

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    RandomBackgroundDirective,
    ThemeSelectorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    CustomersModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'da'},
    CookieService,
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: options },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: options },
    { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: options },
    { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: options },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${location.origin}/assets/i18n/`);
}
