<mat-expansion-panel class="mb-3" [formGroup]="form">
  <mat-expansion-panel-header>
    <mat-panel-title>Historik</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="column" fxLayoutGap="5px">
    <mat-form-field class="select-digest">
      <mat-label>Digest type</mat-label>
      <mat-select formControlName="digest">
        <mat-option
          *ngFor="let digest of applicantDigests"
          [value]="digest"
        >{{'grant-admin.history-settings.digest.select.' + digest | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle ngDefaultControl
      formControlName="force_digest"
    >Tving digest</mat-slide-toggle>
    <mat-slide-toggle ngDefaultControl
      formControlName="production_unit_on_top"
    >Vis P-nummer historik over CVR-nummer historik</mat-slide-toggle>
  </div>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
