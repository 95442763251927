<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Support</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Support e-mail (ansøgningsskema)</dt>
      <dd>{{value.email}}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45" fxLayout="column">
        <label>E-mail</label>
        <mat-form-field>
          <input type="email" matInput formControlName="email">
          <mat-hint>Support e-mail, som vises i ansøgningsskemaet ved fejl.</mat-hint>
          <mat-error *ngIf="form.controls['email'].hasError('required')">Obligatorisk.</mat-error>
          <mat-error *ngIf="form.controls['email'].hasError('email')">Ugyldige email.</mat-error>
          <mat-error *ngIf="form.controls['email'].hasError('maxlength')">Højst 100 tegn.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
