<mat-expansion-panel class="mb-3">
  <mat-expansion-panel-header>
    <mat-panel-title>Søsterfonde</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <div fxFlex="45" class="settings-hint">
      <p>Søsterfonde er fonde, som er knyttet til samme administration. Det er muligt at overføre ansøgninger til søsterfonde.</p>
      <dl class="settings-list">
        <dt>Søsterfonde</dt>
        <dd *ngIf="!value || value?.length === 0">Ingen.</dd>

        <dd *ngFor="let s of value">{{ getCustomer(s.subdomain) }}</dd>
      </dl>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="45" class="settings-list">
      <dt>Overfør sagsbehandlingsdokumenter</dt>
      <dd>{{ customer.settings.transfer?.case_documents ? 'Ja' : 'Nej' }}</dd>

      <dt>Overfør dialogbeskeder</dt>
      <dd>{{ customer.settings.transfer?.messages ? 'Ja' : 'Nej' }}</dd>

      <dt>Overfør indstilling</dt>
      <dd>{{ customer.settings.transfer?.recommendation ? 'Ja' : 'Nej' }}</dd>
    </dl>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.enabled">
    <form [formGroup]="form" fxFlex="45" fxLayout="column">
      <p>Angiv submænet på nye søskendefonde.</p>
      <div fxLayout="column" formArrayName="siblings" class="mb-3">
        <div class="sibling mb-2" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let s of siblings.controls; let i = index" [formGroup]="siblingAt(i)">
          <mat-form-field>
            <mat-label>Subdomæne</mat-label>
            <input
              type="text"
              matInput
              formControlName="subdomain"
              [readonly]="s.value.subdomain === customer.subdomain"
            />
          </mat-form-field>
          <mat-checkbox ngDefaultControl formControlName="share_history" [disabled]="s.value.subdomain === customer.subdomain">
            Del historik
          </mat-checkbox>
          <mat-checkbox ngDefaultControl formControlName="share_profiles" [disabled]="s.value.subdomain === customer.subdomain">
            Del profiler
          </mat-checkbox>
          <button
            mat-icon-button
            matTooltip="Slet søsterfond"
            (click)="removeSibling(i)"
            [disabled]="s.value.subdomain === customer.subdomain"
          ><mat-icon>delete</mat-icon></button>
        </div>
        <div>
          <button
            mat-raised-button
            color="accent"
            matTooltip="Tilføj intern afsender"
            (click)="addSibling()"
          >Tilføj søskende fond</button>
        </div>
      </div>
      <mat-hint><b>OBS!</b> HUSK også at oprette <i>{{customer.name}}</i> som søskende fond i ny oprettede søskendefonde</mat-hint>
    </form>
    <div fxFlex="45" fxLayout="column" fxLayoutGap="15px" class="mb-3" [formGroup]="transfer">
      <mat-checkbox ngDefaultControl formControlName="case_documents">Sagsbehandling</mat-checkbox>
      <mat-checkbox ngDefaultControl formControlName="messages">Dialog</mat-checkbox>
      <mat-checkbox ngDefaultControl formControlName="recommendation">Indstilling</mat-checkbox>
    </div>
  </div>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
