<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Mail
      <mat-icon
        *ngIf="value.disable_deliveries"
        matTooltip="Udgående mail deaktiveret."
        class="ml-3 mail-alert"
      >mail_off</mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt [ngClass]="{ 'mail-alert': value.disable_deliveries }">Udgående mail</dt>
      <dd [ngClass]="{ 'mail-alert': value.disable_deliveries }">{{value.disable_deliveries ? 'Deaktiveret' : 'Aktive'}}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="45" class="settings-list">
      <dt>Fast afsender</dt>
      <dd>{{value.from || 'Nej' }}</dd>

      <dt>Fast svar-til</dt>
      <dd>{{value.reply_to || 'Nej' }}</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" >
      <div fxFlex="45" fxLayout="column">
        <mat-slide-toggle
          formControlName="disable_deliveries"
        >Deaktiver alle udgående mail</mat-slide-toggle>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex="45" fxLayout="column">
        <div fxLayout="column" class="mb-3">
          <label>Fast afsender</label>
          <mat-form-field>
            <input type="email" matInput formControlName="from">
            <mat-hint>Angiv eventuelt fast afsender, hvis fonden ikke understøtter svar på beskeder fra GrantOne.</mat-hint>
            <mat-error *ngIf="form.controls['from'].hasError('email')">Ugyldige email.</mat-error>
            <mat-error *ngIf="form.controls['from'].hasError('maxlength')">Højst 100 tegn.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>Fast svar-til</label>
          <mat-form-field>
            <input type="email" matInput formControlName="reply_to">
            <mat-hint>Angiv eventuelt fast svar-tid, hvis fonden ikke understøtter svar på beskeder fra GrantOne.</mat-hint>
            <mat-error *ngIf="form.controls['reply_to'].hasError('email')">Ugyldige email.</mat-error>
            <mat-error *ngIf="form.controls['reply_to'].hasError('maxlength')">Højst 100 tegn.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
