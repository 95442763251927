<h2 mat-dialog-title>
  Profil
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <form [formGroup]="profile">
    <div fxLayout="row">
      <div formArrayName="logins" fxLayout="column" fxFlex="32">
        <h4>Logins</h4>
        <mat-checkbox ngDefaultControl (change)="onLoginTypeToggle('email', $event.checked)"
                      [checked]="hasLoginType('email')">Email</mat-checkbox>
        <mat-checkbox ngDefaultControl (change)="onLoginTypeToggle('nemid', $event.checked)"
                      [checked]="hasLoginType('nemid')">NemID</mat-checkbox>
        <mat-checkbox ngDefaultControl (change)="onLoginTypeToggle('mitid', $event.checked)"
                      [checked]="hasLoginType('mitid')">MitID</mat-checkbox>
      </div>

      <div formArrayName="signups" fxLayout="column" fxFlex="32">
        <h4>Signups</h4>
        <mat-checkbox ngDefaultControl (change)="onSignupTypeToggle('email', $event.checked)"
                      [checked]="hasSignupType('email')">Email</mat-checkbox>
        <mat-checkbox ngDefaultControl (change)="onSignupTypeToggle('nemid', $event.checked)"
                      [checked]="hasSignupType('nemid')">NemID</mat-checkbox>
        <mat-checkbox ngDefaultControl (change)="onSignupTypeToggle('mitid', $event.checked)"
                      [checked]="hasSignupType('mitid')">MitID</mat-checkbox>
      </div>

      <div fxLayout="column" fxFlex="32">
        <h4>Generelle indstillinger</h4>
        <mat-checkbox ngDefaultControl formControlName="force">Kun tilgå via profil</mat-checkbox>
        <mat-checkbox ngDefaultControl formControlName="confirmation">Email bekræftelse ved oprettelse</mat-checkbox>
        <mat-checkbox ngDefaultControl formControlName="account_alert_on_payment_request">
          <span style="white-space: break-spaces">Vis en popup med kontooplysninger, når ansøger indsender anmodning.</span>
        </mat-checkbox>
        <mat-checkbox ngDefaultControl formControlName="allow_copy_of_rejection">Tillad kopi af afslag.</mat-checkbox>
      </div>
    </div>
    <mat-hint *ngIf="!isLoginSameAsSignUp()">
      <b>OBS! </b>Login og Signup metoder stemmer <b>ikke</b> overens
    </mat-hint>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="primary" (click)="saveDialog()">Gem</button>
</mat-dialog-actions>
