<h2 mat-dialog-title>
  Ansøgningsscore
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <form [formGroup]="form" fxFlex="100" fxLayout="column" fxLayoutGap="10px">
    <div
      formArrayName="range"
      class="mb-3"
      *ngFor="let value of range.controls; let i = index"
    >
      <div [formGroupName]="i" class="category" fxLayout="row" fxLayoutAlign="space-between center">
        <span fxFlex="5">#{{i + 1}}</span>
        <mat-form-field color="accent" class="mr-3" fxFlex="37">
          <mat-label>Label</mat-label>
          <input
            type="text"
            matInput
            formControlName="label"
            placeholder="Label"
          >
        </mat-form-field>
        <mat-form-field color="accent" fxFlex="37">
          <mat-label>Score værdi</mat-label>
          <input
            type="number"
            matInput
            formControlName="value"
            placeholder="Score værdi"
          >
        </mat-form-field>
        <div fxFlex="20" fxLayoutAlign="end">
          <button
            *ngIf="range.length >= 1"
            mat-icon-button
            matTooltip="Slet score"
            matTooltipPosition="above"
            (click)="doRemoveScore(i)"
          ><mat-icon>delete</mat-icon></button>
        </div>
        <mat-divider *ngIf="i < range.length - 1"></mat-divider>
      </div>
    </div>
    <button
      mat-raised-button
      color="accent"
      matTooltip="Tilføj ny score"
      (click)="doAppendScore()"
    ><mat-icon>add</mat-icon>Tilføj score</button>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="doSave()">Gem</button>
</mat-dialog-actions>
