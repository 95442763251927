<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Sikkerhed</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Token låst til IP-adresse</dt>
      <dd>{{value.skip_ip_check ? 'Nej' : 'Ja'}}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="45" class="settings-list">
      <dt>Modtager sikkerheds notifikationer</dt>
      <dd *ngIf="!value.mail?.length">Brugere med samme rettighed.</dd>
      <dd *ngFor="let m of value.mail">{{m}}</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45" fxLayout="column">
        <p>Hvis deaktiveret kan samme token bruges på tværs af IP-adresser.</p>
        <mat-checkbox ngDefaultControl formControlName="skip_ip_check">Deaktiver token IP-kontrol.</mat-checkbox>
      </div>
      <div fxFlex="45" fxLayout="column" formArrayName="mail">
        <p>Ved ændring i væsentlige rettigheder notificeres brugere med retten til at ændre brugeropsætning - med mindre der er angivet en eller flere emailadresser, som skal modtage sikkerhedsnotifikationer.</p>
        <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let _ of mails.controls; let i = index">
          <mat-form-field >
            <mat-label>E-mail</mat-label>
            <input
              type="email"
              matInput
              [formControl]="mailAt(i)"
            />
          </mat-form-field>
          <button
            mat-icon-button
            matTooltip="Slet e-mail"
            (click)="doRemoveMail(i)"
          ><mat-icon>delete</mat-icon></button>
        </div>
        <div>
          <button
            mat-raised-button
            color="accent"
            matTooltip="Tilføj e-mail"
            (click)="doAddMail()"
          >Tilføj e-mail</button>
        </div>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
