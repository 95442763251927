import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ExtendedModule, FlexModule } from '@angular/flex-layout';

import { TranslateModule } from '@ngx-translate/core';

import { TrendModule } from 'ngx-trend';

import { CustomersComponent } from './customers.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { NewCustomerComponent } from './new-customer/new-customer.component';
import { CustomersRoutingModule } from './customers-routing.module';
import { CustomerViewComponent } from './customer-view/customer-view.component';
import { CustomerDetailsComponent } from './customer-view/customer-details/customer-details.component';
import { CustomerComponentsComponent } from './customer-view/customer-components/customer-components.component';
import { CustomerSettingsComponent } from './customer-view/customer-settings/customer-settings.component';
import { WealthManagerComponent } from './customer-view/wealth-manager/wealth-manager.component';
import { CoreModule } from '../core/core.module';
import { CustomerStatsComponent } from './customer-view/customer-details/customer-stats/customer-stats.component';
import { CustomerUserListComponent } from './customer-view/customer-user-list/customer-user-list.component';
import {
  UserInfoDialogComponent
} from './customer-view/customer-user-list/user-info-dialog/user-info-dialog.component';
import { UserCardComponent } from './customer-view/customer-user-list/user-card/user-card.component';
import {
  EditCustomerDialogComponent
} from './customer-view/customer-details/edit-customer-dialog/edit-customer-dialog.component';
import {
  ApprovalConfirmationSettingsDialogComponent,
  ArchiveSettingsDialogComponent,
  AutomationSettingsDialogComponent,
  CommentsSettingsDialogComponent,
  CriteriaSettingsDialogComponent,
  ForecastSettingsDialogComponent,
  PaymentReceiptsSettingsDialogComponent,
  PortalSettingsDialogComponent,
  ProfileSettingsDialogComponent,
  RecommendationsSettingsDialogComponent,
  RefusalSettingsDialogComponent,
  ScoringSettingsDialogComponent,
  SplitGrantsSettingsDialogComponent,
  TaxSettingsDialogComponent
} from './customer-view/customer-components/component-settings';
import {
  AccountingSettingsComponent,
  AuthenticationSettingsComponent,
  CustomValueSettingsComponent,
  DialogSettingsComponent,
  GdprSettingsComponent,
  OverviewXlsxSettingsComponent,
  PaymentsSettingsComponent,
  SupportSettingsComponent,
  SecuritySettingsComponent,
  SmsSettingsComponent,
  SmtpSettingsComponent,
  CaseWorkerSettingsComponent,
  ExtraColumnsSettingsComponent
} from './customer-view/customer-settings';
import { CustomerDisplaysComponent } from './customer-view/customer-displays/customer-displays.component';
import { EditDisplayDialogComponent } from './customer-view/customer-displays/edit-display-dialog/edit-display-dialog.component';
import { FieldsSortingComponent } from './customer-view/customer-displays/edit-display-dialog/fields-sorting/fields-sorting.component';
import {YesNoDialogComponent} from "./shared-components/yes-no-dialog/yes-no-dialog.component";
import {CustomerStylingComponent} from "./customer-view/customer-styling/customer-styling.component";
import {ColorPickerModule} from "ngx-color-picker";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {CustomerProfilesComponent} from './customer-view/customer-profiles/customer-profiles.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { HistorySettingsComponent } from './customer-view/customer-settings/history-settings/history-settings.component';
import {
  MaterializedViewSettingsComponent
} from "./customer-view/customer-settings/materialized-view-settings/materialized-view-settings.component";
import { BulkActionSettingsDialogComponent } from './customer-view/customer-components/component-settings/bulk-action-settings-dialog/bulk-action-settings-dialog.component';
import {QuestionnaireCloneComponent} from './customer-view/questionnaire-clone/questionnaire-clone.component';
import { FontSelectionComponent } from './customer-view/customer-styling/font-selection/font-selection.component';
import { ColorSelectionComponent } from './customer-view/customer-styling/color-selection/color-selection.component';
import {
  SiblingsSettingsComponent
} from "./customer-view/customer-settings/siblings-settings/siblings-settings.component";
import { VotesSettingsComponent } from './customer-view/customer-settings/votes-settings/votes-settings.component';
import {
  CustomerIntegrationUsersComponent
} from './customer-view/customer-integration-users/customer-integration-users.component';
import {
  EditIntegrationUserDialogComponent
} from './customer-view/customer-integration-users/edit-integration-user-dialog/edit-integration-user-dialog.component';
import {MatChipsModule} from "@angular/material/chips";
import { EditCategoryDialogComponent } from './customer-view/customer-details/edit-category-dialog/edit-category-dialog.component';
import {MatMenuModule} from "@angular/material/menu";
import { ImportSettingsComponent } from './customer-view/customer-settings/import-settings/import-settings.component';
import { EmployeeNumberSettingsDialogComponent } from './customer-view/customer-components/component-settings/employee-number-settings-dialog/employee-number-settings-dialog.component';
import {
  AISettingsDialogComponent
} from './customer-view/customer-components/component-settings/ai-settings-dialog/ai-settings-dialog.component';
import {
  WarningsSettingsComponent
} from './customer-view/customer-settings/warnings-settings/warnings-settings.component';


@NgModule({
  declarations: [
    CustomersComponent,
    CustomersListComponent,
    NewCustomerComponent,
    CustomerViewComponent,
    CustomerDetailsComponent,
    CustomerComponentsComponent,
    CustomerSettingsComponent,
    WealthManagerComponent,
    CustomerStatsComponent,
    CustomerUserListComponent,
    UserInfoDialogComponent,
    UserCardComponent,
    EditCustomerDialogComponent,
    ApprovalConfirmationSettingsDialogComponent,
    ArchiveSettingsDialogComponent,
    AutomationSettingsDialogComponent,
    CommentsSettingsDialogComponent,
    CriteriaSettingsDialogComponent,
    ForecastSettingsDialogComponent,
    ProfileSettingsDialogComponent,
    RecommendationsSettingsDialogComponent,
    RefusalSettingsDialogComponent,
    ScoringSettingsDialogComponent,
    SplitGrantsSettingsDialogComponent,
    TaxSettingsDialogComponent,
    AccountingSettingsComponent,
    AuthenticationSettingsComponent,
    CaseWorkerSettingsComponent,
    CustomValueSettingsComponent,
    DialogSettingsComponent,
    GdprSettingsComponent,
    OverviewXlsxSettingsComponent,
    PaymentsSettingsComponent,
    SupportSettingsComponent,
    SecuritySettingsComponent,
    SmsSettingsComponent,
    SmtpSettingsComponent,
    CustomerDisplaysComponent,
    EditDisplayDialogComponent,
    FieldsSortingComponent,
    YesNoDialogComponent,
    CustomerProfilesComponent,
    CustomerStylingComponent,
    ExtraColumnsSettingsComponent,
    HistorySettingsComponent,
    MaterializedViewSettingsComponent,
    BulkActionSettingsDialogComponent,
    QuestionnaireCloneComponent,
    FontSelectionComponent,
    ColorSelectionComponent,
    SiblingsSettingsComponent,
    PaymentReceiptsSettingsDialogComponent,
    PortalSettingsDialogComponent,
    VotesSettingsComponent,
    CustomerIntegrationUsersComponent,
    EditIntegrationUserDialogComponent,
    EditCategoryDialogComponent,
    ImportSettingsComponent,
    EmployeeNumberSettingsDialogComponent,
    AISettingsDialogComponent,
    WarningsSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    TrendModule,
    FlexModule,
    CoreModule,
    CustomersRoutingModule,
    MatGridListModule,
    MatDialogModule,
    TranslateModule,
    MatCheckboxModule,
    DragDropModule,
    ExtendedModule,
    MatToolbarModule,
    MatRadioModule,
    MatSliderModule,
    ColorPickerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatMenuModule,
  ]
})
export class CustomersModule { }
