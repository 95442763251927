import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { defaultSmtpSettings, SmtpSettings } from '../../../../core/models';

@Component({
  selector: 'app-smtp-settings',
  templateUrl: './smtp-settings.component.html',
  styleUrls: ['./smtp-settings.component.scss']
})
export class SmtpSettingsComponent implements OnInit, OnChanges {

  @Input() settings: SmtpSettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  value: SmtpSettings = {
    ...defaultSmtpSettings
  };
  form: FormGroup = new FormGroup({
    disable_deliveries: new FormControl<boolean>(false),
    from: new FormControl<string|null>(null, [Validators.maxLength(100), Validators.email]),
    reply_to: new FormControl<string|null>(null, [Validators.maxLength(100), Validators.email]),
  });

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...defaultSmtpSettings, ...this.settings };
      }
      this.form.patchValue(this.value);
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultSmtpSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

}
