<h2 mat-dialog-title>
  <span *ngIf="createNew">Opret ny standard søjlevisning</span>
  <span *ngIf="!createNew">Rediger "{{display.name}}" søjlevisning</span>
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <div fxFlex fxLayout="column" [formGroup]="form">
    <mat-form-field>
<!--       Todo: Check for dublicate-->
      <mat-label>Navn på visning</mat-label>
      <input type="text" matInput formControlName="name" required>
    </mat-form-field>

    <div fxFlex fxLayoutAlign="space-between">
      <div fxFlex="45">
        <p>Ledige søjler</p>
        <div class="example-list">
          <div class="example-box" *ngFor="let field of availableFields; let i = index" (click)="addField(field, i)">
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            {{'fields.' + field.key | translate}}
            <button mat-icon-button>
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div fxFlex="45" >
        <p>Valgte søjler</p>
        <div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="example-box moveable" *ngFor="let field of chosenFields; let i = index" cdkDrag>
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            <button mat-icon-button><mat-icon>drag_indicator</mat-icon></button>
            {{'fields.' + field.key | translate}}
            <button mat-icon-button (click)="removeField(field, i)">
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </div>

      </div>
    </div>

    <div formArrayName="fields_sorting">
      <app-fields-sorting
        *ngFor="let _ of 3 | fill; let i = index"
        [sortingNumber]="i"
        [chosenSortingFields]="fieldsSortingArray"
        [availableSortingFields]="availableSortingFields"
        (addSorting)="addSorting($event)"
        (removeSorting)="removeSorting($event)"
      ></app-fields-sorting>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid || chosenFields.length === 0">Gem</button>
</mat-dialog-actions>
