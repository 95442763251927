<div mat-dialog-title class="mb-3">
  <div fxLayoutAlign="space-between">
    <h1>Rediger {{customer.name}}</h1>
    <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <mat-divider></mat-divider>
</div>
<div mat-dialog-content>
  <form [formGroup]="customerForm">
    <div fxLayout="row" fxLayoutGap="3em">
      <div fxLayout="column" fxFlex="32">
        <h4>Basis informationer</h4>
        <mat-form-field>
          <mat-label>Fondens navn</mat-label>
          <input type="text"
                 matInput
                 formControlName="name">
          <mat-error *ngIf="getControl('name').hasError('required')">Skal udfyldes</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Subdomæne</mat-label>
          <input type="text"
                 matInput
                 formControlName="subdomain">
          <mat-error *ngIf="getControl('subdomain').hasError('required')">Skal udfyldes</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>VAT no.</mat-label>
          <input type="number"
                 matInput
                 formControlName="reg_no">
          <mat-error *ngIf="getControl('reg_no').hasError('required')">Skal udfyldes</mat-error>
        </mat-form-field>
        <div formGroupName="address_attributes" fxLayout="column">
          <mat-form-field>
            <mat-label>C/O</mat-label>
            <input type="number"
                   matInput
                   formControlName="care_of">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Adresse</mat-label>
            <input type="text"
                   matInput
                   formControlName="street">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Post nr.</mat-label>
            <input type="number"
                   matInput
                   formControlName="zip">
          </mat-form-field>
          <mat-form-field>
            <mat-label>By</mat-label>
            <input type="text"
                   matInput
                   formControlName="city">
          </mat-form-field>
          <mat-form-field>
             <mat-label>Land</mat-label>
             <mat-select formControlName="country">
              <mat-option *ngFor="let country of countries" [value]="country.value">{{country.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxFlex="32">
        <h4>Kontaktperson</h4>
        <mat-form-field>
          <mat-label>Kontakt person</mat-label>
          <input type="text"
                 matInput
                 formControlName="contact">
        </mat-form-field>
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input type="email"
                 matInput
                 formControlName="email">
          <mat-error *ngIf="getControl('email').hasError('email')">Det er ikke en email</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Telefon</mat-label>
          <input type="text"
                 matInput
                 formControlName="phone">
        </mat-form-field>
        <div class="mt-3" fxFlex="32" fxLayout="column" formGroupName="settings">
          <h4 class="m-0">Sprog</h4>
          <ng-container formGroupName="board">
            <mat-form-field>
              <mat-label>Sprog i board</mat-label>
                <mat-select formControlName="language">
                  <mat-option value="da">Dansk</mat-option>
                  <mat-option value="en">Engelsk</mat-option>
                </mat-select>
              </mat-form-field>
          </ng-container>
          <mat-form-field>
            <mat-label>Mulige sprog i base</mat-label>
            <mat-select [formControl]="getLanguageControl()" multiple>
              <mat-option *ngFor="let lang of languages" [value]="lang.code">{{lang.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="getLanguageControl().hasError('required')">Skal udfyldes</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Kunde tag</mat-label>
            <input type="text"
                   matInput
                   formControlName="tag">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxFlex="32" formGroupName="wealth_manager_attributes">
        <h4>Bank informationer</h4>
        <mat-form-field>
          <mat-label>Navn</mat-label>
          <mat-select formControlName="bank">
            <mat-option value="generic">Generisk</mat-option>
            <mat-option value="danske_forvaltning">Danske Forvaltning</mat-option>
            <mat-option value="danske_bank">Danske Bank</mat-option>
            <mat-option value="jyske_bank">Jyske Bank</mat-option>
            <mat-option value="bank_connect">BankConnect</mat-option>
            <mat-option value="bank_integration">BankIntegration</mat-option>
          </mat-select>
        </mat-form-field>
<!--        Todo: fixme-->
<!--        <mat-form-field>-->
<!--          <mat-label>Valuta</mat-label>-->
<!--          <mat-select formControlName="currency">-->
<!--            <mat-option value="DKK">Danish Kroner</mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
        <mat-form-field>
          <mat-label>Varetægts nr. (Costody no.)</mat-label>
          <input type="number"
                 matInput
                 formControlName="custody_no">
        </mat-form-field>
        <mat-form-field>
          <mat-label>NemKonto</mat-label>
          <mat-select formControlName="nem_konto">
            <mat-option [value]="true">Ja</mat-option>
            <mat-option [value]="false">Nej</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Eksporter betalinger</mat-label>
          <mat-select formControlName="exports_enabled">
            <mat-option [value]="true">Ja</mat-option>
            <mat-option [value]="false">Nej</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Accounting</mat-label>
          <mat-select formControlName="accounting">
            <mat-option [value]="true">Ja</mat-option>
            <mat-option [value]="false">Nej</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Kapital over 1 mil.</mat-label>
          <mat-select formControlName="capital">
            <mat-option [value]="'more_than_one_million'">Ja</mat-option>
            <mat-option [value]="'less_than_one_million'">Nej</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-raised-button (click)="saveCustomer()" color="primary">Gem</button>
</div>
