<div mat-dialog-title class="mb-3">
  <h1>{{user.name}}</h1>
  <mat-divider></mat-divider>
</div>
<div mat-dialog-content>
  <h3>Brugerens rettigheder</h3>
  <mat-accordion fxLayout="column" fxFlexFill>
    <mat-expansion-panel class="permission-panel mat-background-accent" *ngFor="let permissionArea of permissions | keyvalue">
      <mat-expansion-panel-header color="warn">
        {{'permissions.' + permissionArea.key + '.title' | translate}}
      </mat-expansion-panel-header>
      <mat-list *ngFor="let permissionTerm of getPermission(permissionArea.key) | keyvalue">
        <mat-list-item>
          <div fxLayoutAlign="space-between center" fxFlex="100">
            <span>{{'permissions.' + permissionArea.key + '.' + permissionTerm.key | translate}}</span>
            <mat-icon *ngIf="permissionTerm.value">check_circle</mat-icon>
            <mat-icon *ngIf="!permissionTerm.value">block</mat-icon>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-raised-button mat-dialog-close color="primary">Luk</button>
</div>
