<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Sagsbehandler</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Fjern fra afviste og arkiverede sager</dt>
      <dd>{{value.remove_delay}} dag(e)</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="45" class="settings-list">
      <dt>Send daglig "Tildelte sager"-mail</dt>
      <dd>{{value.daily_reminder ? 'Ja' : 'Nej' }}</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45" fxLayout="column">
        <label>Fjern fra afviste og arkiverede sager (dage)</label>
        <mat-form-field>
          <input type="number" matInput formControlName="remove_delay">
          <mat-hint>Antal dage før sagsbehandleren fjernes fra arkiverede og afviste sager.</mat-hint>
          <mat-error *ngIf="form.controls['remove_delay'].hasError('required')">Obligatorisk.</mat-error>
          <mat-error *ngIf="form.controls['remove_delay'].hasError('min')">Mindst 1 dag.</mat-error>
        </mat-form-field>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex="45" fxLayout="column">
        <mat-slide-toggle
          formControlName="daily_reminder"
        >Send daglig "Tildelte sager"-mail</mat-slide-toggle>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
