<mat-expansion-panel [formGroup]="form">
  <mat-expansion-panel-header>Søgning</mat-expansion-panel-header>
  <mat-form-field>
    <mat-label>Index opdatering</mat-label>
    <input
      matInput
      type="number"
      formControlName="schedule"
    />
    <mat-hint>Hvor ofte skal søgnings index opdateres, i timer.</mat-hint>
  </mat-form-field>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="edit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="cancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
