<h2 mat-dialog-title>
  Vurderingskriterier
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <p>Hvis fonden benytter vurderingskriterier kan der enten være en samlet vurdering eller en individuel vurdering pr bruger.</p>
  <form [formGroup]="form">
    <div class="mb-2">
      <mat-checkbox ngDefaultControl  formControlName="individual">Benyt individuelle kriterier</mat-checkbox>
    </div>
    <div class="mb-2">
      <mat-form-field>
        <mat-label>Antal decimaler på vægtet procent</mat-label>
        <input
          type="number"
          matInput
          formControlName="pct_precision"
        >
        <mat-hint>Antal decimaler i procent angivelse for vægtede scores.</mat-hint>
        <mat-error *ngIf="form.controls['pct_precision'].hasError('min')"><strong>Mindst</strong> 0 decimaler.</mat-error>
        <mat-error *ngIf="form.controls['pct_precision'].hasError('max')"><strong>Højst</strong> 2 decimaler.</mat-error>
        <mat-error *ngIf="form.controls['pct_precision'].hasError('pattern')"><strong>Kun</strong> heltal.</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="accent" mat-dialog-close>Luk</button>
  <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="doSave()">Gem</button>
</mat-dialog-actions>
