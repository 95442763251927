<div mat-dialog-title class="mb-3">
  <div fxLayoutAlign="space-between">
    <h1>{{ edit ? 'Redigere' : 'Opret' }} integrations bruger</h1>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>
  <mat-divider></mat-divider>
</div>
<div mat-dialog-content [formGroup]="form" fxLayout="column">
  <mat-form-field color="accent">
    <mat-label>Navn</mat-label>
    <input matInput type="text" formControlName="username">
  </mat-form-field>
  <mat-form-field color="accent">
    <mat-label>Kode</mat-label>
    <input matInput formControlName="password">
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="start center">
    <label>IP adresser</label>
    <button mat-icon-button (click)="addIpAddress()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div *ngFor="let ipAddress of form.controls.ip_addresses.controls; index as i">
    <mat-form-field color="accent">
      <mat-label>IP adresse</mat-label>
      <input matInput type="text" [formControl]="ipAddress">
    </mat-form-field>
    <button mat-icon-button (click)="removeIpAddress(i)">
      <mat-icon >remove</mat-icon>
    </button>
  </div>
  <ng-container formGroupName="permissions">
    <mat-form-field
      *ngFor="let keyValue of recordsPermission | keyvalue"
      formGroupName="records"
      color="accent"
    >
      <mat-label>{{keyValue.key}}</mat-label>
      <mat-select [formControlName]="keyValue.key" multiple>
        <mat-option
          *ngFor="let value of keyValue.value"
          [value]="value"
        >{{value}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      floatLabel="always"
      color="accent"
      class="underline-none"
      formGroupName="posting"
    >
      <mat-label>Redigere posteringer</mat-label>
      <mat-slide-toggle formControlName="manage"></mat-slide-toggle>
      <textarea matInput hidden></textarea>
    </mat-form-field>
  </ng-container>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-raised-button (click)="confirm()" [disabled]="form.invalid" color="primary">
    {{edit ? 'Gem' : 'Opret'}}
  </button>
</div>
