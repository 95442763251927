import confetti from "canvas-confetti";

export function celebration(): void {
  confetti1();
  confetti2();
  let audio = new Audio();
  audio.src = '../../../assets/data/succes.mp3';
  audio.load();
  audio.play();
}

function confetti1(): void {
  const i = setInterval(() => {
    confetti({
      particleCount: 750,
      startVelocity: 30,
      spread: 360,
      origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.2
      }
    });
  }, 300)
  setTimeout(() => {
    clearInterval(i)
  }, 3000)
}

function confetti2(): void {
  var duration = 30 * 100;
  var end = Date.now() + duration;

  (function frame() {
    // launch a few confetti from the left edge
    confetti({
      particleCount: 10,
      angle: 60,
      spread: 55,
      origin: { x: 0 }
    });
    // and launch a few from the right edge
    confetti({
      particleCount: 10,
      angle: 120,
      spread: 55,
      origin: { x: 1 }
    });

    // keep going until we are out of time
    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  }());
}
