import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Subscription} from 'rxjs';

import { Category, Customer, UsageStatistics } from '../../../core/models';
import { CustomersService } from '../../../core/services';
import { EditCustomerDialogComponent } from './edit-customer-dialog/edit-customer-dialog.component';
import { YesNoDialogService } from '../../shared-components/yes-no-dialog/yes-no-dialog.service';
import { EditCategoryDialogComponent } from "./edit-category-dialog/edit-category-dialog.component";
import {celebration} from "../../../core/helpers/celebrate.helper";

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnDestroy {

  UsageStatistics = UsageStatistics;

  customer!: Customer;
  categories: Category[];
  subscription: Subscription = new Subscription();

  isActivated: boolean = false

  constructor(private router: Router,
              private route: ActivatedRoute,
              private customersService: CustomersService,
              private yesNoDialog: YesNoDialogService,
              private dialog: MatDialog) {
    this.route.data.subscribe((data) => {
      this.customer = data['customer'];
      this.isActivated = this.customer.status !== "trial"
      const categoriesSub = this.customersService.getCategories(this.customer.subdomain).subscribe(categories => {
        this.categories = categories;
      }, () => {
        this.categories = [];
      })
      this.subscription.add(categoriesSub);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  editCustomer(): void {
    const dialogRef = this.dialog.open(
      EditCustomerDialogComponent, {
      data: {
        customer: this.customer
      },
      width: '1000px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(customer => {
      if (customer !== undefined) {
        customer.settings = Object.assign({}, this.customer.settings, customer.settings);
        this.customersService.updateWithWealthManager(customer).subscribe(value => {
          this.customersService.customerChange.next();
          this.customer = value;
        })
      }
    });
  }

  doActivate() {
    // trigger reload of current page, which reloads data
    this.yesNoDialog.open({
      title: 'Aktiver fond',
      content: `Vil du aktivere fonden?`,
      yes: 'Ja',
      no: 'Annuler'
    }).afterClosed().subscribe(result => {
      if (result) {
        celebration(); // celebrate - yay!
        this.customersService.activate(this.customer)
          .subscribe(() => {
            this.customersService.customerChange.next();
            this.isActivated = true;
            this.router.navigate([`/customers/${this.customer.id}`])
          });
      }
    });
  }

  editCategory(category: Category): void {
    this.dialog.open<EditCategoryDialogComponent>(EditCategoryDialogComponent, {
      data: {category: category},
      width: '1000px',
      panelClass: 'dialog'
    }).afterClosed().subscribe(result => {
      if (result) {
        this.customersService.updateJournalNoPattern(result, category.id, this.customer.subdomain)
          .subscribe(updatedCategory => {
            const idx = this.categories.findIndex(c => c.id === category.id)
            this.categories[idx] = updatedCategory;
            // all done
          })
      }
    })
  }
}
