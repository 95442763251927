<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>SMS</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Send notifikation på bevillingsbrev</dt>
      <dd>{{ value.approval ? 'Ja' : 'Nej' }}</dd>

      <dt>Send notifikation på alm. bekseder</dt>
      <dd>{{ value.all_messages ? 'Ja' : 'Nej' }}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="45" class="settings-list">
      <dt>Send notifikation på afslag</dt>
      <dd>{{ value.refusal ? 'Ja' : 'Nej' }}</dd>

      <dt>Afsender på beskeder</dt>
      <dd>{{value.sender}}</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <mat-checkbox ngDefaultControl fxFlex="45" formControlName="approval">Send notifikation på bevillingsbrev</mat-checkbox>
      <mat-checkbox ngDefaultControl fxFlex="45" formControlName="refusal">Send notifikation på afslag</mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <mat-checkbox ngDefaultControl fxFlex="45" formControlName="all_messages">Send notifikation på alm. beskeder</mat-checkbox>
      <div fxFlex="45" fxLayout="column">
        <label>Afsender</label>
        <mat-form-field>
          <input type="text" matInput formControlName="sender">
          <mat-hint>Afsender på sms. Højst 12 tegn og ingen danske specialtegn.</mat-hint>
          <mat-error *ngIf="form.controls['sender'].hasError('required')">Obligatorisk.</mat-error>
          <mat-error *ngIf="form.controls['sender'].hasError('pattern')">Ugyldige tegn.</mat-error>
          <mat-error *ngIf="form.controls['sender'].hasError('minlength')">Mindst 2 tegn.</mat-error>
          <mat-error *ngIf="form.controls['sender'].hasError('maxlength')">Højst 12 tegn.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
