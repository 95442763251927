<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Økonomi / Overblik</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Søjler</dt>
      <dd *ngFor="let column of value.columns">{{'grant-admin.overview-xlsx-settings.column.' + column | translate }}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <div fxFlex="45">
      Definitionen af rapporten <em>Økonomi &gt; Overblik</em> som indeholder alle bevilgede sager, hvor der
      enten er skyldige beløb eller har været posteringer i den valgte periode.
    </div>
  </div>
  <form [formGroup]="form" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.enabled">
    <div fxFlex="45" fxLayout="column">
      <h3>Valgte søjler</h3>
      <div class="overview-xlsx-column-list" cdkDropList (cdkDropListDropped)="onDrop($event)">
        <div class="overview-xlsx-column" fxLayout="row" fxLayoutAlign="start center"
             *ngFor="let column of columns.value; let idx = index" cdkDrag>
          <button mat-icon-button><mat-icon>drag_indicator</mat-icon></button>
          <div fxFlex="grow">{{'grant-admin.overview-xlsx-settings.column.' + column | translate }}</div>
          <button mat-icon-button (click)="doRemoveColumn(idx)">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div fxFlex="45" fxLayout="column">
      <h3>Tilgængelige søjler</h3>
      <div class="overview-xlsx-available-list">
        <div class="overview-xlsx-column" fxLayout="row" fxLayoutAlign="start center" *ngFor="let column of availableColumns">
          <div fxFlex="grow">{{'grant-admin.overview-xlsx-settings.column.' + column | translate }}</div>
          <button mat-icon-button (click)="doAddColumn(column)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
