import { Component } from '@angular/core';

@Component({
  selector: 'theme-selector',
  template: `
    <div class="theme-selector">
      <button mat-icon-button aria-label="Theme selection" [matMenuTriggerFor]="selectThemeMenu">
        <mat-icon>format_color_fill</mat-icon>
      </button>
      <mat-menu #selectThemeMenu>
        <button mat-menu-item value="dark-theme"
                class="theme dark" [ngClass]="{active: selectedTheme === 'dark-theme'}"
                (click)="changeTheme('dark-theme')">
          <mat-icon *ngIf="selectedTheme !== 'dark-theme'">radio_button_unchecked</mat-icon>
          <mat-icon *ngIf="selectedTheme === 'dark-theme'">radio_button_checked</mat-icon>
          <span class="text">
            Standard
          </span>
        </button>
        <button mat-menu-item value="light-theme"
                class="theme light" [ngClass]="{active: selectedTheme === 'light-theme'}"
                (click)="changeTheme('light-theme')">
          <mat-icon *ngIf="selectedTheme !== 'light-theme'">radio_button_unchecked</mat-icon>
          <mat-icon *ngIf="selectedTheme === 'light-theme'">radio_button_checked</mat-icon>
          <span class="text">
            Lys
          </span>
        </button>
        <button mat-menu-item value="azure-theme"
                class="theme azure" [ngClass]="{active: selectedTheme === 'azure-theme'}"
                (click)="changeTheme('azure-theme')">
          <mat-icon *ngIf="selectedTheme !== 'azure-theme'">radio_button_unchecked</mat-icon>
          <mat-icon *ngIf="selectedTheme === 'azure-theme'">radio_button_checked</mat-icon>
          <span class="text">
            Azure & Blå
          </span>
        </button>
        <button mat-menu-item value="cyan-theme"
                class="theme cyan" [ngClass]="{active: selectedTheme === 'cyan-theme'}"
                (click)="changeTheme('cyan-theme')">
          <mat-icon *ngIf="selectedTheme !== 'cyan-theme'">radio_button_unchecked</mat-icon>
          <mat-icon *ngIf="selectedTheme === 'cyan-theme'">radio_button_checked</mat-icon>
          <span class="text">
            Cyan & Orange
          </span>
        </button>
      </mat-menu>
    </div>
  `,
  styles: [`
    @import '../styles/colors';
    .theme-selector {
      padding: 16px;
    }
    h3 {
      margin-bottom: 8px;
    }
    mat-button-toggle-group {
      display: flex;
      gap: 8px;
    }
    mat-button-toggle {
      transition: background-color 0.3s ease, color 0.3s ease;
    }
    button[mat-menu-item].theme {
      &.light {
        &.active, &:hover {
          background-color: $light-primary; // the global color dont work properly.
          mat-icon {
            color: $light-accent;
          }
        }
      }
      &.dark {
        &.active, &:hover {
          background-color: $dark-primary; // the global color dont work properly.
          mat-icon {
            color: $dark-accent;
          }
        }
      }
      &.cyan {
        &.active, &:hover {
          background-color: $cyan-primary; // the global color dont work properly.
          mat-icon {
            color: $cyan-accent;
          }
        }
      }
      &.azure {
        &.active, &:hover {
          background-color: $azure-primary; // the global color dont work properly.
          mat-icon {
            color: $azure-accent;
          }
        }
      }
    }
  `],
})
export class ThemeSelectorComponent {
  selectedTheme: string = 'dark-theme'

  currentPalette: { primary: string; primaryContrast: string; primaryRgb: string;
    accent: string; accentContrast: string; accentRgb: string } = {
    primary: '', primaryContrast: '', primaryRgb: '',
    accent: '', accentContrast: '', accentRgb: ''
  };

  private themeColors = {
    'light-theme': {
      primary: '#45d172', primaryContrast: '#000000', primaryRgb: '69, 209, 113',
      accent: '#50504b', accentContrast: '#ffffff', accentRgb: '80, 80, 75'
    },
    'dark-theme': {
      primary: '#5ad759', primaryContrast: '#000000', primaryRgb: '90, 215, 89',
      accent: '#1e1e1e', accentContrast: '#e0e0e0', accentRgb: '30, 30, 30'
    },
    'azure-theme': {
      primary: '#3f51b5', primaryContrast: '#ffffff', primaryRgb: '63, 81, 181',
      accent: '#283593', accentContrast: '#ffffff', accentRgb: '40, 53, 147'
    },
    'cyan-theme': {
      primary: '#00bcd4', primaryContrast: '#000000', primaryRgb: '0, 188, 212',
      accent: '#ff9800', accentContrast: '#000000', accentRgb: '255, 152, 0'
    },
  };

  constructor() {
    const currentTheme = localStorage.getItem('grant-admin.theme') ?? 'dark-theme';
    document.body.classList.add(currentTheme);
    this.changeTheme(currentTheme);
  }

  changeTheme(theme: string) {
    const themes = Object.keys(this.themeColors);
    themes.forEach(t => document.body.classList.remove(t));
    this.setPaletteByTheme(theme);
    this.selectedTheme = theme;
    document.body.classList.add(theme);

  }

  setPaletteByTheme(theme: string): void {
    this.currentPalette = this.themeColors[theme];

    console.log(this.currentPalette.primary)
    document.documentElement.style.setProperty('--primary-color', this.currentPalette.primary);
    document.documentElement.style.setProperty('--primary-contrast-color', this.currentPalette.primaryContrast);
    document.documentElement.style.setProperty('--primary-color-rgb', this.currentPalette.primaryRgb);

    document.documentElement.style.setProperty('--accent-color', this.currentPalette.accent);
    document.documentElement.style.setProperty('--accent-contrast-color', this.currentPalette.accentContrast);
    document.documentElement.style.setProperty('--accent-color-rgb', this.currentPalette.accentRgb);
    localStorage.setItem('grant-admin.theme', theme);
  }
}
