<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Regnskab/kontoplan</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="32" class="settings-list">
      <dt>Krediteringskonto ved udbetaling</dt>
      <dd>{{value.payment_credit_account}}</dd>

      <dt>Krediteringskonto ved bevilling</dt>
      <dd>{{value.provision_account_no}}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="32" class="settings-list">
      <dt>Debiteringskonto ved udbetaling</dt>
      <dd>{{value.payment_debit_account}}</dd>

      <dt>Debiteringskonto ved bevilling</dt>
      <dd>{{value.account_no}}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="32" class="settings-list">
      <dt>Mellemregningskonto ved udbetaling</dt>
      <dd>{{value.intermediate_account || '--' }}</dd>
      <dt>Posteringstekst (e-conomic)</dt>
      <dd>{{economic_posting_text.value}}</dd>
    </dl>
  </div>
  <form [formGroup]="form" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.enabled">
    <div fxFlex="32">
      <div fxLayout="column" class="mb-3">
        <label>Krediteringskonto ved udbetaling</label>
        <mat-form-field>
          <input type="text" matInput formControlName="payment_credit_account">
          <mat-hint>Den konto, som skal krediteres ved en udbetaling</mat-hint>
          <mat-error *ngIf="form.controls['payment_credit_account'].hasError('required')">Obligatorisk.</mat-error>
          <mat-error *ngIf="form.controls['payment_credit_account'].hasError('pattern')">Ugyldigt konto.</mat-error>
          <mat-error *ngIf="form.controls['payment_credit_account'].hasError('minlength')">Mindst 3 tegn.</mat-error>
          <mat-error *ngIf="form.controls['payment_credit_account'].hasError('maxlength')">Højst 20 tegn.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="column" class="mb-3">
        <label>Krediteringskonto ved bevilling</label>
        <mat-form-field>
          <input type="text" matInput formControlName="provision_account_no">
          <mat-hint>Den konto, som krediteres ved bevilling af en sag.</mat-hint>
          <mat-error *ngIf="form.controls['provision_account_no'].hasError('required')">Obligatorisk.</mat-error>
          <mat-error *ngIf="form.controls['provision_account_no'].hasError('pattern')">Ugyldigt konto.</mat-error>
          <mat-error *ngIf="form.controls['provision_account_no'].hasError('minlength')">Mindst 3 tegn.</mat-error>
          <mat-error *ngIf="form.controls['provision_account_no'].hasError('maxlength')">Højst 20 tegn.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div fxFlex="32">
      <div fxLayout="column" class="mb-3">
        <label>Debiteringskonto ved udbetaling</label>
        <mat-form-field>
          <input type="text" matInput formControlName="payment_debit_account">
          <mat-hint>Debiteringskonto ved udbetaling</mat-hint>
          <mat-error *ngIf="form.controls['payment_debit_account'].hasError('required')">Obligatorisk.</mat-error>
          <mat-error *ngIf="form.controls['payment_debit_account'].hasError('pattern')">Ugyldigt konto.</mat-error>
          <mat-error *ngIf="form.controls['payment_debit_account'].hasError('minlength')">Mindst 3 tegn.</mat-error>
          <mat-error *ngIf="form.controls['payment_debit_account'].hasError('maxlength')">Højst 20 tegn.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="column" class="mb-3">
        <label>Debiteringskonto ved bevilling*</label>
        <mat-form-field>
          <input type="text" matInput formControlName="account_no">
          <mat-hint>Den konto, som debiteres ved bevilling af en sag.</mat-hint>
          <mat-error *ngIf="form.controls['account_no'].hasError('required')">Obligatorisk.</mat-error>
          <mat-error *ngIf="form.controls['account_no'].hasError('pattern')">Ugyldigt konto.</mat-error>
          <mat-error *ngIf="form.controls['account_no'].hasError('minlength')">Mindst 3 tegn.</mat-error>
          <mat-error *ngIf="form.controls['account_no'].hasError('maxlength')">Højst 20 tegn.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div fxFlex="32">
      <div fxLayout="column" class="mb-3">
        <label>Mellemregningskonto ved betaling</label>
        <mat-form-field>
          <input type="text" matInput formControlName="intermediate_account">
          <mat-hint>Den konto, som posteres hvis betalingerne ikke skal posteres til banken.</mat-hint>
          <mat-error *ngIf="form.controls['intermediate_account'].hasError('pattern')">Ugyldigt konto.</mat-error>
          <mat-error *ngIf="form.controls['intermediate_account'].hasError('minlength')">Mindst 3 tegn.</mat-error>
          <mat-error *ngIf="form.controls['intermediate_account'].hasError('maxlength')">Højst 20 tegn.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="column" class="mb-3">
        <label>Posteringstekst (e-conomic)*</label>
        <mat-form-field>
          <input type="text" matInput [formControl]="economic_posting_text">
          <mat-hint>Skabelon for posteringstekst. Flettefelter journal_no, title, name, entity_id og note.</mat-hint>
          <mat-error *ngIf="economic_posting_text.hasError('required')">Skal angives.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="primary" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="accent" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="primary" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
